function setRootFontSize() {
  const doc = document.documentElement
  const deviceWidth = doc.clientWidth > 375 ? 375 : doc.clientWidth
  if (deviceWidth >= 1080) {
    doc.style.fontSize = '100px'
  } else {
    doc.style.fontSize = (deviceWidth / 1080) * 100 + 'px'
  }
}
setRootFontSize()
window.addEventListener('resize', setRootFontSize)
